import React, { useState } from "react";
import data from "./data.json";
import Logo1 from "./logo1";
const Header = ({ tab, setTab, setPage, setSideNavHidden, setTabs }) => {
  const [selected,setSelected] = useState(['HOME','HOME'])
  const [display,setDisplay] = useState(false)
  return (
    <div className="header-container">
      <div className="img-body">
        <Logo1/> 
        <div>
          <h1 className="header-h1">LSATman.com</h1>
          <h3 className="header-h3">Your customized LSAT prep tutor</h3>
        </div>
      </div>
      <nav className="nav-container">
        {window.screen.width >= 800 &&
          Object.keys(data).map((el,i) => {
            if (el === "HOME") {
              return (
                <button
                  key={el+ '-' + i}
                  className = {tab === el ? "b-shadow-lightseagreen underline nav-button" : "nav-button"}
                  onClick={() => {
                    setTab(el);
                    setPage(el);
                    setTabs(el,el)
                    setSideNavHidden(true);
                  }}
                >
                  {el}
                </button>
              );
            }
            return (
              <button
                key={el+ '-' + i}
                className = {tab === el ? "b-shadow-lightseagreen underline nav-button" : "nav-button"}
                onClick={() => {
                  let p = Object.keys(data[el])[0]
                  setTab(el);
                  setPage(p);
                  setTabs(el,p)
                  setSideNavHidden(false);
                }}
              >
                {el}
              </button>
            );
          })}
        {window.screen.width < 800 && <div className='header-select-wrapper'><div className="column header-select-container">
          <div className="header-select" onClick={() => setDisplay(!display)}>{selected[0]} &emsp; {display ? <span>&#9650;</span> :<span>&#9660;</span>}</div>

            {display && Object.keys(data).map((el,i) => {
              if(el !== selected[0]){
                let page = Object.keys(data[el])[0]
                return (
                  <div 
                    key={el+ '-' + i} 
                    className="header-select options" 
                    id={page}
                    onClick={() => {
                      setTab(el);
                      setPage(page);
                      setTabs(el,page)
                      setSideNavHidden(true);
                      setDisplay(false)
                      setSelected([el,page])
                    }}>{el}</div>
                )} 
            })}
        </div>
        </div>}
      </nav>
    </div>
  );
};

export default Header;
