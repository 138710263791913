import React from "react";

const Success = ({ setDisplaySuccess }) => {
  return (
    <div className="overlay">
      <div className="success-box">
        <div className="form-top">
          <div className="button" onClick={() => setDisplaySuccess(false)}>
            &#x2715;
          </div>
        </div>
        <h1>Sign up successful!</h1>
        <p>You should receive an email from LSATman.com within 24-48 hours.</p>
      </div>
    </div>
  );
};

export default Success;
