import React from "react";

const Failure = ({ setDisplayFailure }) => {
  return (
    <div className="overlay">
      <div className="fail-box">
        <div className="form-top">
          <div className="button" onClick={() => setDisplayFailure(false)}>
            &#x2715;
          </div>
        </div>
        <h1>An error has occurred!</h1>
        <p>Please try again later</p>
      </div>
    </div>
  );
};

export default Failure;
