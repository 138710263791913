import React, { useEffect, useRef, useState } from "react";

const Signup = ({ form, setForm, toggleSignup, formSubmit }) => {
  const [submitDisabled, disableSubmit] = useState(true);
  const [badValues, setBadValues] = useState(
    new Set(["fname", "lname", "email", "phone", "zip", "goal"])
  );
  const [message, setMessage] = useState("Please fill all rows");
  const [radioCheck, setRadio] = useState("signup");
  const initial = useRef(true)

  const times = [
    '9:00AM','9:30AM','10:00AM','10:30AM','11:00AM',
    '11:30AM','12:00PM','12:30PM','1:00PM','1:30PM',
    '2:00PM','2:30PM','3:00PM','3:30PM','4:00PM',
    '4:30PM','5:00PM','5:30PM','6:00PM','6:30PM'
  ];

  const dateArr = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
  ];

  const nameRegex = new RegExp(/[A-Za-z\'-]{1,}/g);
  const emailRegex = new RegExp(/[A-Za-z\d.]{1,}@[A-Za-z\d.]{2,}.[A-Za-z\d.]{2,4}/g);
  const currentDate = new Date();
  const day = currentDate.getDay();

  useEffect(() => {
    setForm({
      'formType': 'signup',
      'best':'Logic Games',
      'worst':'Reading Comprehension',
      'primary':'Logic Games',
      'secondary':'None',
      'start_day':dateArr[day],
      'day_logic':'ONLY',
      'end_day':'None',
      'start_time':'9:00AM',
      'end_time':'10:00AM',
      'tz':'eastern'})
    if(initial.current){
      let user = window.localStorage.getItem('user')
      if(user){
        setRadio('schedule')
        let userArr = user.split(',')
        let email = userArr[0]
        let phone = userArr[1]
        if(email.match(emailRegex) && !phoneValidation(phone)){
          setForm({'formType':'schedule','email':email,'phone':phone})
          const phoneEl = document.getElementById('phone-el')
          const emailEl = document.getElementById('email-el')
          phoneEl.classList.remove('bg-error')
          phoneEl.classList.add('bg-valid')
          emailEl.classList.remove('bg-error')
          emailEl.classList.add('bg-valid')
          disableSubmit(false)
        }
      }
      initial.current = false
    }
  },[])

  const validFormTest = (formArg=null) => {
    if (formArg === 'schedule' && !( badValues.has('phone') || badValues.has('email') )) {
      disableSubmit(false);
    } else if((form.formType === 'schedule' && formArg !=='signup') && !( badValues.has('phone') || badValues.has('email') )) {
      disableSubmit(false);
    } else if (badValues.size === 0) {
      disableSubmit(false);
    } else disableSubmit(true)
  };
 
  
  const firstDateIdx = day % 7;
  const lastDateIdx = firstDateIdx + 7;
  const dates = dateArr.slice(firstDateIdx, lastDateIdx);

  const determineMessage = () => {
    if (form.formType === 'signup'){
      if (badValues.has("fname")) return setMessage("First name missing");
      if (badValues.has("lname")) return setMessage("Last name missing");
      if (badValues.has("zip")) {
        if (form?.zip?.length) return setMessage("Check zipcode field");
        else return setMessage("Zipcode missing");
      }
      if (badValues.has("goal")){
        if (form?.goal?.length) return setMessage("Check Goal score field");
        else return setMessage("Goal score missing");
      }
      if (badValues.has("sections"))
        return setMessage("Same best and worst section");
    }
    if (badValues.has("phone")) {
      if (form.phone.length) return setMessage("Check cell phone field");
      else return setMessage("Cell phone missing");
    }
    if (badValues.has("email")) {
      if (form.zip.length) return setMessage("Check email field");
      else return setMessage("Email missing");
    }
  };

  const phoneValidation = (phone) => {
    let temp = "";
    for (let el in phone) {
      if ("1234567890".includes(el)) {
        temp += el;
      }
    }
    if (temp[0] === "1") {
      temp = temp.substring(1);
    }
    return temp.length !== 10
  }

  return (
    <div className="overlay">
      {/* <form action="mailto:stephen@lsatman.com" className="signup-form"> */}
      <form className="signup-form">
        <div className="form-top">
          Signup to schedule a lesson
          <div className="button" onClick={() => toggleSignup(false)}>
            &#x2715;
          </div>
        </div>
        <div className="fields-container">
          <div>
            <label>Signup:</label>
            <input
              type="radio"
              checked={radioCheck === "signup"}
              onChange={() => {
                setRadio("signup");
                setForm({...form,formType:"signup"})
                validFormTest('signup')
              }}
            />
            <label>Schedule:</label>
            <input
              type="radio"
              checked={radioCheck === "schedule"}
              onChange={() => {
                setRadio("schedule");
                setForm({...form,formType:"schedule"});
                validFormTest('schedule')
              }}
            />
          </div>
          {radioCheck === "signup" && (
            <div>
              <label>First Name:</label>
              <input
                value={form.fname}
                className={badValues.has("fname") ? "bg-error" : "bg-valid"}
                onChange={(e) => {
                  if (
                    e.target.value.match(nameRegex) === e.target.value ||
                    !e.target.value.length
                  ) {
                    setBadValues(badValues.add("fname"));
                    disableSubmit(true);
                  } else {
                    badValues.delete("fname");
                    setBadValues(new Set(badValues));
                    validFormTest();
                  }
                  determineMessage();
                  setForm({ ...form, fname: e.target.value });
                }}
              />
            </div>
          )}
          {radioCheck === "signup" && (
            <div>
              <label>Last Name:</label>
              <input
                value={form.lname}
                className={badValues.has("lname") ? "bg-error" : "bg-valid"}
                onChange={(e) => {
                  if (
                    e.target.value.match(nameRegex) === e.target.value ||
                    !e.target.value.length
                  ) {
                    setBadValues(badValues.add("lname"));
                    disableSubmit(true);
                  } else {
                    badValues.delete("lname");
                    setBadValues(new Set(badValues));
                    validFormTest();
                  }
                  determineMessage();
                  setForm({ ...form, lname: e.target.value });
                }}
              />
            </div>
          )}
          <div>
            <label>Cell phone:</label>
            <input
              id='phone-el'
              value={form.phone}
              className={badValues.has("phone") ? "bg-error" : "bg-valid"}
              placeholder={"US only - area code first"}
              onChange={(e) => {
                if (phoneValidation(e.target.value)) {
                  setBadValues(badValues.add("phone"));
                  disableSubmit(true);
                } else {
                  badValues.delete("phone");
                  setBadValues(new Set(badValues));
                  validFormTest();
                }
                determineMessage();
                setForm({ ...form, phone: e.target.value });
              }}
            />
          </div>
          <div>
            <label>Email:</label>
            <input
              id='email-el'
              value={form.email}
              className={badValues.has("email") ? "bg-error" : "bg-valid"}
              placeholder="example@example.com"
              onChange={(e) => {
                if(!e.target.value.match(emailRegex)) {
                  setBadValues(badValues.add("email"));
                  disableSubmit(true);
                } else {
                  badValues.delete("email");
                  setBadValues(new Set(badValues));
                  validFormTest();
                }
                determineMessage();
                setForm({ ...form, email: e.target.value });
              }}
            />
          </div>
          {radioCheck === "signup" && (
            <div>
              <label>Zipcode:</label>
              <input
                value={form.zip}
                className={badValues.has("zip") ? "bg-error" : "bg-valid"}
                placeholder="00501 - 99950 ex: 08701"
                onChange={(e) => {
                  if (
                    e.target.value.length !== 5 ||
                    !(parseInt(e.target.value) > 501) ||
                    !(parseInt(e.target.value) < 99950)
                  ) {
                    setBadValues(badValues.add("zip"));
                    disableSubmit(true);
                  } else {
                    badValues.delete("zip");
                    setBadValues(new Set(badValues));
                    validFormTest();
                  }
                  determineMessage();
                  setForm({ ...form, zip: e.target.value });
                }}
              />
            </div>
          )}
          {radioCheck === "signup" && (
            <div>
              <label>Goal score:</label>
              <input
                value={form.goal}
                className={badValues.has("goal") ? "bg-error" : "bg-valid"}
                placeholder="120 - 180 ex: 157"
                onChange={(e) => {
                  if (
                    parseInt(e.target.value) <= 180 &&
                    parseInt(e.target.value) >= 120
                  ) {
                    badValues.delete("goal");
                    setBadValues(new Set(badValues));
                    validFormTest();
                  } else {
                    setBadValues(badValues.add("goal"));
                    disableSubmit(true);
                  }
                  determineMessage();
                  setForm({ ...form, goal: e.target.value });
                }}
              />
            </div>
          )}
          {radioCheck === "signup" && (
            <div>
              <label>Best Section:</label>
              <select
                value={form.best}
                className={badValues.has("sections") ? "bg-error" : "bg-valid"}
                onChange={(e) => {
                  if (
                    e.target.value !== null &&
                    e.target.value === form.worst
                  ) {
                    setBadValues(badValues.add("sections"));
                    disableSubmit(true);
                  } else {
                    badValues.delete("sections");
                    setBadValues(new Set(badValues));
                    validFormTest();
                  }
                  determineMessage();
                  setForm({ ...form, best: e.target.value });
                }}
              >
                <option value="Logic Games">Logic Games</option>
                <option value="Logical Reasoning">Logical Reasoning</option>
                <option value="Reading Comprehension">
                  Reading Comprehension
                </option>
                <option value='Unsure'>I don't know</option>
              </select>
            </div>
          )}
          {radioCheck === "signup" && (
            <div>
              <label>Worst Section:</label>
              <select
                value={form.worst}
                className={badValues.has("sections") ? "bg-error" : "bg-valid"}
                onChange={(e) => {
                  if (e.target.value !== null && e.target.value === form.best) {
                    setBadValues(badValues.add("sections"));
                    disableSubmit(true);
                  } else {
                    badValues.delete("sections");
                    setBadValues(new Set(badValues));
                    validFormTest();
                  }
                  determineMessage();
                  setForm({ ...form, worst: e.target.value });
                }}
              >
                <option value="Logic Games">Logic Games</option>
                <option value="Logical Reasoning">Logical Reasoning</option>
                <option value="Reading Comprehension">
                  Reading Comprehension
                </option>
                <option value='Unsure'>I don't know</option>
              </select>
            </div>
          )}
          {radioCheck === "schedule" && (
            <div>
              <label>Primary Focus:</label>
              <select
                value={form.primary}
                onChange={(e) => {
                  setForm({ ...form, primary: e.target.value });
                }}
              >
                <option value="Logic Games">Logic Games</option>
                <option value="Logical Reasoning">Logical Reasoning</option>
                <option value="Reading Comprehension">
                  Reading Comprehension
                </option>
              </select>
            </div>
          )}
          {radioCheck === "schedule" && (
            <div>
              <label>Secondary Focus:</label>
              <select
                value={form.secondary}
                onChange={(e) => {
                  setForm({ ...form, secondary: e.target.value });
                }}
              >
                <option value="None">None</option>
                <option value="Logic Games">Logic Games</option>
                <option value="Logical Reasoning">Logical Reasoning</option>
                <option value="Reading Comprehension">
                  Reading Comprehension
                </option>
              </select>
            </div>
          )}
          {radioCheck === "schedule" &&
            <hr className="signup-btn-line form-line"/>
          }
          {radioCheck === "schedule" && (
            <div className="column">
              <label>Requested Days:</label>
              <div className="row">
                <select
                  value={form.start_day}
                  onChange={(e) => {
                    setForm({ ...form, start_day: e.target.value });
                  }}
                >
                  {dates.map((day) => (
                    <option value={day}>
                      {day.slice(0, 1).toUpperCase() + day.slice(1)}
                    </option>
                  ))}
                </select>
                <select
                  value={form.day_logic}
                  onChange={(e) => {setForm({ ...form, day_logic: e.target.value });}}
                >
                  <option value='ONLY'>ONLY</option>
                  <option value="AND">AND</option>
                  <option value="OR">OR</option>
                  <option value="THROUGH">THROUGH</option>
                </select>
                <select
                  value={form.end_day}
                  onChange={(e) => {
                    setForm({ ...form, end_day: e.target.value });
                  }}
                >
                  <option value="None">None</option>
                  {dates.map((day) => (
                    <option value={day}>
                      {day.slice(0, 1).toUpperCase() + day.slice(1)}
                    </option>
                  ))}
                </select>
              </div>

            </div>
          )}
          {radioCheck === "schedule" &&
            <hr className="signup-btn-line form-line"/>
          }
          {radioCheck === "schedule" && (
            <div className='column'>
              <label>Requested Times:</label>
              <label>BETWEEN</label>
              <div className="row">
                <select
                  value={form.start_time}
                  onChange={(e) => {
                    setForm({ ...form, start_time: e.target.value });
                  }}
                >
                  {times.slice(0,-2).map((time) => (
                    <option value={time}>
                      {time}
                    </option>
                  ))}
                </select>
                AND
                <select
                  value={form.end_time}
                  onChange={(e) => {setForm({ ...form, end_time: e.target.value });
                  }}
                >
                  {times.slice(2).map((time) => (
                    <option value={time}>
                      {time}
                    </option>
                  ))}
                </select>
                <select
                  value={form.tz}
                  onChange={(e) => {setForm({ ...form, tz: e.target.value })}}
                >
                  <option value='eastern'>Eastern</option>
                  <option value='central'>Central</option>
                  <option value='mountain'>Mountain</option>
                  <option value='pacific'>Pacific</option>
                </select>
                </div>
            </div>
          )}
          {!submitDisabled && (
            <button className="submit-button" onClick={(e) => formSubmit(e)}>Submit</button>
          )}
          {submitDisabled && (
            <div className="disabled-tooltip">
              <button className="disabled-button">Submit</button>
              <span className="disabled-notification">{message}</span>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Signup;

// var at_symbol = null
// var dot_symbol = null
// if(e.target.value.length < 6){
//   setBadValues(badValues.add('email'))
//   disableSubmit(true)
// }
// else if(!e.target.value.includes('@')){
//   setBadValues(badValues.add('email'))
//   disableSubmit(true)
// }
// else if(!e.target.value.includes('.')){
//   setBadValues(badValues.add('email'))
//   disableSubmit(true)
// }
// else {
//   at_symbol = e.target.value.indexOf('@')
//   dot_symbol = e.target.value.lastIndexOf('.')
// }
// var first = e.target.value.substring(0,at_symbol)
// var second = e.target.value.substring(at_symbol+1,dot_symbol)
// var last = e.target.value.substring(dot_symbol+1)
// var A = 'A'.charCodeAt(0)
// var Z = 'Z'.charCodeAt(0)
// var a = 'a'.charCodeAt(0)
// var z = 'z'.charCodeAt(0)

// var firstChar = first.charCodeAt(0)
// var secondChar = second.charCodeAt(0)
// var thirdChar = last.charCodeAt(0)
// if(at_symbol > dot_symbol){
//   setBadValues(badValues.add('email'))
//   disableSubmit(true)
// }
// else if(first.length === 0 || !((firstChar >= a && firstChar <= z) || (firstChar >= A && firstChar <= Z ))){
//   setBadValues(badValues.add('email'))
//   disableSubmit(true)
// }
// else if(second.length === 0 || !((secondChar >= a && secondChar <= z) || (secondChar >= A && secondChar <= Z ))){
//   setBadValues(badValues.add('email'))
//   disableSubmit(true)
// }
// else if(last.length < 2 || !((thirdChar >= a && thirdChar <= z) || (thirdChar >= A && thirdChar <= Z ))){
//   setBadValues(badValues.add('email'))
//   disableSubmit(true)
// }
