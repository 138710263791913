import { useState } from "react";
import "./App.css";
import Header from "./Header";
import SideNav from "./SideNav";
import data from "./data.json";
import Page from "./Page";
import Signup from "./Signup";
import Success from "./Success";
import Failure from "./Failure";

function App() {
  const [tab, setTab] = useState("HOME");
  const [page, setPage] = useState("HOME");
  const [sideNavHidden, setSideNavHidden] = useState(true);
  const [displaySignup, toggleSignup] = useState(false);
  const [form, setForm] = useState({'best':'Reading Comprehension','worst':'Logic Games'})
  const [submissionInProgress, setSubmissionInProgress] = useState(false)
  const [displaySuccess, setDisplaySuccess] = useState(false)
  const [displayFailure, setDisplayFailure] = useState(false)
  const [previousPages, setPreviousPages] = useState([])

  // TODO: MAKE A FORM FOR SESSIONS
  const formSubmit = (e) => {
    e.preventDefault()
    setSubmissionInProgress(true)
    const signupFields = ['fname','lname','phone','email','zip','goal','best','worst']
    const scheduleFields = ['phone','email','primary','secondary','start_day','day_logic','end_day','start_time','end_time','tz']
    const fields = form.formType === 'signup' ? signupFields : scheduleFields;
    const submissionFields = {}
    for(let key of fields){
      console.log(key,form[key])
      submissionFields[key] = form[key]
    }
    console.log(submissionFields)
    fetch('https://mathemagical-server.herokuapp.com/lsatman/'+form.formType,{
      method: 'POST',
      mode:'cors',
      headers:{
        'Content-Type':'application/json'
      },
      body: JSON.stringify(submissionFields)
    }).then(res => res.json()).then(response => {
      if( form.formType === 'signup' && response.response === 'success'){
        setDisplaySuccess(true)
        window.localStorage.setItem("user",`${form.email},${form.phone}`)
      }
      else if(response.response === 'success'){
        setDisplaySuccess(true)
      }
      toggleSignup(false)
      setSubmissionInProgress(false)
    }).catch(err => {
      toggleSignup(false)
      setSubmissionInProgress(false)
      setDisplayFailure(true)
    })
  }

  const setTabs = (selectedTab,selectedPage)=>{
    if(Array.isArray(selectedPage)){
      selectedPage = selectedPage[1]
    }
    let filteredPages = previousPages.filter(el => selectedPage !== el[1])
    let num = Math.floor((window.innerWidth+50)/220)
    if(filteredPages.length >num){
      filteredPages = filteredPages.slice(1,num)
    }
    setPreviousPages([...filteredPages,[selectedTab,selectedPage]])
  }
  const tabClick = (e) => {
    if(e.target.className === 'tab'){
      let tab = e.target.id.split('-')[0]
      let page = e.target.innerText
      let error = true
      for(let t of Object.keys(data)){
        for(let p of Object.keys(data[t])){
          if(!p.search(page)){
            setPage(p)
            setTabs(tab,p)
            setTab(tab)
            error = false
          } 
        }
      }
      if(error) window.alert('Sorry, it appears we\'ve hit a snag.')
    }
  }

  return (
    <div className="App">
      <Header
        tab={tab}
        setTab={setTab}
        setPage={setPage}
        setTabs={setTabs}
        setSideNavHidden={setSideNavHidden}
      />
      <button className="signup-button" onClick={() => toggleSignup(true)}>
        <div>Signup</div> <div><hr  className="signup-btn-line"/></div> <div>Schedule</div>
      </button>
      <div className="row">
        <SideNav
          tab={tab}
          hidden={sideNavHidden}
          page={page}
          setPage={setPage}
          setTabs={setTabs}
          setHidden={setSideNavHidden}
        />
        <Page data={data[tab][page]} previousPages={previousPages} sideNavHidden={sideNavHidden} tabClick={tabClick} />
      </div>
      {submissionInProgress &&  <div id='loading' className="overlay"></div>}
      {displaySignup && <Signup form={form} setForm={setForm} toggleSignup={toggleSignup} formSubmit = {formSubmit}/>}
      {displaySuccess && <Success setDisplaySuccess={setDisplaySuccess}/>}
      {displayFailure && <Failure setDisplayFailure={setDisplayFailure}/>}
    </div>
  );
}

export default App;
