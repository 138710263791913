import React from 'react'

const Logo1 = () => {
  return (
    <svg version="1.1" viewBox="0.0 0.0 960.0 720.0" fill="none" stroke="none" strokeLinecap="square" strokeMiterlimit="10">
      <clipPath id="p.0">
        <path d="m0 0l960.0 0l0 720.0l-960.0 0l0 -720.0z" clipRule="nonzero"/>
        </clipPath>
        <g clipPath="url(#p.0)">
          <path fill="#000000" fillOpacity="0.0" d="m0 0l960.0 0l0 720.0l-960.0 0z" fillRule="evenodd"/>
          <path fill="#cc0000" d="m292.3517 58.658794l327.3386 0l0 593.22833l-327.3386 0z" fillRule="evenodd"/>
          <path fill="#ffffff" d="m369.9344 59.726078l249.7638 0l0 456.34644l-249.7638 0z" fillRule="evenodd"/>
          <path fill="#000000" d="m369.91602 268.95782l249.7638 0l0 35.77954l-249.7638 0z" fillRule="evenodd"/>
          <path fill="#000000" d="m402.19946 58.658794l0 98.39369l-32.283447 0l0 -98.39369z" fillRule="evenodd"/>
          <path fill="#000000" d="m369.91602 157.05229l249.7638 0l0 32.314957l-249.7638 0z" fillRule="evenodd"/>
          <path fill="#000000" d="m402.19946 58.658794l217.48035 0l0 35.77953l-217.48035 0z" fillRule="evenodd"/>
          <path fill="#000000" d="m619.69556 177.77159l-0.03149414 93.76378l-35.08661 0l0.03149414 -93.76378z" fillRule="evenodd"/>
          <path fill="#000000" d="m369.91565 386.5495l124.8819 -288.063l124.8819 288.063z" fillRule="evenodd"/>
          <path fill="#ffffff" d="m460.2467 268.958l34.55121 -79.59056l34.551147 79.59056z" fillRule="evenodd"/>
          <path fill="#ffffff" d="m458.71915 98.485565l82.299225 0l0 58.582672l-82.299225 0z" fillRule="evenodd"/>
          <path fill="#ffffff" d="m408.7244 386.9265l40.682648 -85.66928l94.50873 0l40.68268 85.66928z" fillRule="evenodd"/>
          <path fill="#000000" d="m512.70605 268.958l0 247.62207l-35.77954 0l0 -247.62207z" fillRule="evenodd"/>
          <path fill="#000000" fillOpacity="0.0" d="m292.3517 490.30447l327.3386 0l0 187.46457l-327.3386 0z" fillRule="evenodd"/>
          <path fill="#000000" d="m311.6486 632.42444l0 -99.25l19.765625 0l23.484375 70.265625q3.25 9.828125 4.75 14.703125q1.6875 -5.421875 5.28125 -15.921875l23.765625 -69.046875l17.65625 0l0 99.25l-12.65625 0l0 -83.078125l-28.84375 83.078125l-11.84375 0l-28.703125 -84.5l0 84.5l-12.65625 0zm105.00174 0l38.109375 -99.25l14.15625 0l40.625 99.25l-14.96875 0l-11.578125 -30.0625l-41.5 0l-10.890625 30.0625l-13.953125 0zm28.640625 -40.75l33.640625 0l-10.359375 -27.5q-4.734375 -12.515625 -7.03125 -20.578125q-1.90625 9.546875 -5.359375 18.953125l-10.890625 29.125zm74.607605 40.75l0 -99.25l13.46875 0l52.140625 77.921875l0 -77.921875l12.59375 0l0 99.25l-13.484375 0l-52.125 -78.0l0 78.0l-12.59375 0z" fillRule="nonzero"/>
          </g>
          </svg>
    )
}

export default Logo1