import React, { useState } from "react";
import "./App.css";

import sideNavElements from "./sideNav.json";

const SideNav = ({ tab, hidden, page, setPage, setHidden, setTabs }) => {
  return (
    <ul>
      {hidden ? (
        <li
          className="side-nav clickable collapsed-side-nav bg-black"
          onClick={() => {
            setHidden(false);
          }}
        >
          &#x2192;
        </li>
      ) : (
        <>
          <li
            className="side-nav clickable bg-black"
            onClick={() => {
              setHidden(true);
            }}
          >
            &#x2715;
          </li>
          {sideNavElements[tab].map((el,i) => {
            if (typeof el === "string") {
              return (
                <li
                  key={el+ '-' + i}
                  className={
                    page === el
                      ? "side-nav clickable italic b-shadow-white"
                      : "side-nav clickable"
                  }
                  onClick={() => {
                    setPage(el);
                    setTabs(tab,el);
                  }}
                >
                  {el}
                </li>
              );
            } else {
              return el[1] !== "" ? (
                <>
                  <li className="side-nav unclickable">{el[0]}</li>
                  <li
                    className={
                      page === el[1]
                        ? "side-nav clickable italic b-shadow-white"
                        : "side-nav clickable"
                    }
                    onClick={() => {
                      setPage(el[1]);
                      setTabs(tab,el[1]);
                    }}
                  >
                    {el[1]}
                  </li>
                </>
              ) : (
                <li className="side-nav unclickable">{el[0]}</li>
              );
            }
          })}
        </>
      )}
    </ul>
  );
};

export default SideNav;
