import React from 'react'

const Page = ({data, previousPages, sideNavHidden,tabClick}) => {
  return (
    <div onClick={(e)=>tabClick(e)}className={sideNavHidden ? 'column page full-page' : 'column page'}>
        <div className='tabs'>
          {previousPages.slice(0,-1).map(
            (p,i) => <div 
              id={p[0]+"-"+i} 
              className='tab'
            >{p[1].length > 12 ? p[1].substring(0,12): p[1]}</div>
        )}
        </div>
        <h1 className='page-h1'>{data[0]}</h1>
        <h3 className='page-h3'>- {data[1]}</h3>
        {data[2].map((s,i) =>{
            return(
                <p className='page-p' key={i}>&emsp;&emsp;{s}</p>
            )
        })}
    </div>
  )
}

export default Page